"use client";
import dynamic from "next/dynamic";
import { DatadogInit, UserContextInit } from "../_components/datadog/rum";
import { ThemeProvider } from "@optimization/widgets/ThemeProvider";
import { HeaperInitScript } from "../_components/heaper/HeaperInitScript";
import { HeaperInit } from "../_components/heaper/heaper";
import ApolloProvider from "./apollo-provider";

const AuthProvider = dynamic(() => import("./auth-provider"), { ssr: false });
const FeatureFlagProvider = dynamic(() => import("./feature-flag-provider"), {
  ssr: false,
});

export default function Providers({ children }: { children: React.ReactNode }) {
  return (
    <html lang="en">
      {process.env.NEXT_PUBLIC_ENVIRONMENT === "production" ? (
        <HeaperInitScript />
      ) : null}
      <body>
        <DatadogInit />
        <ThemeProvider>
          <AuthProvider>
            <ApolloProvider>
              <FeatureFlagProvider>
                <>
                  {process.env.NEXT_PUBLIC_ENVIRONMENT === "production" ? (
                    <HeaperInit />
                  ) : null}
                  <UserContextInit />
                  {children}
                </>
              </FeatureFlagProvider>
            </ApolloProvider>
          </AuthProvider>
        </ThemeProvider>
      </body>
    </html>
  );
}
