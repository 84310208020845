/* eslint-disable turbo/no-undeclared-env-vars */
"use client";
import { ReactNode, useMemo } from "react";
import { useAuth0 } from "@equipmentshare/auth0-react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider as ApolloProviderComponent,
  createHttpLink,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient as createGraphqlWsClient } from "graphql-ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { RetryLink } from "@apollo/client/link/retry";

const gqlApi = process.env.NEXT_PUBLIC_GRAPHQL_API ?? "";
const tokenOverride = process.env.NEXT_PUBLIC_TOKEN_OVERRIDE;

const retryLink = new RetryLink({
  delay: { initial: 300, max: Infinity, jitter: true },
  attempts: {
    max: 5,
    retryIf: (error, _operation) => error?.response?.status !== 401,
  },
});
const httpLink = createHttpLink({ uri: gqlApi });

export default function ApolloProvider({ children }: { children: ReactNode }) {
  const { getAccessTokenSilently: auth0GetToken } = useAuth0();
  const getAccessTokenSilently = useMemo(() => {
    return tokenOverride ? () => tokenOverride : auth0GetToken;
  }, [auth0GetToken]);

  const client = useMemo(() => {
    const wsLink = new GraphQLWsLink(
      createGraphqlWsClient({
        url: gqlApi.replace("https", "wss"),
        connectionParams: async () => ({
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
        }),
        shouldRetry: () => true,
      }),
    );
    const authLink = setContext(async (_, { headers }) => {
      const token = await getAccessTokenSilently();
      return {
        headers: { ...headers, authorization: token ? `Bearer ${token}` : "" },
      };
    });
    const apiLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === "OperationDefinition" &&
          definition.operation === "subscription"
        );
      },
      wsLink,
      retryLink.concat(httpLink),
    );
    return new ApolloClient({
      name: "optimization-app",
      link: authLink.concat(apiLink),
      cache: new InMemoryCache(),
    });
  }, [getAccessTokenSilently]);

  return (
    <ApolloProviderComponent client={client}>
      {children}
    </ApolloProviderComponent>
  );
}
