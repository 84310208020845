"use client";
import { useIdentity } from "@equipmentshare/auth0-react";
import { useHeaper } from "@equipmentshare/heaper";

export function HeaperInit() {
  const { idToken, accessToken } = useIdentity();
  useHeaper(
    "Fleet",
    process.env.NEXT_PUBLIC_ENVIRONMENT === "production"
      ? "Production"
      : "Staging",
    {
      intercom: false,
      idToken,
      accessToken,
    },
  );
  return null;
}
