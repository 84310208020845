"use client";

import { EuiProvider } from "@elastic/eui";
import { ReactNode } from "react";
import "@elastic/eui/dist/eui_theme_light.css";
import "./global.css";

export function ThemeProvider({ children }: { children: ReactNode }) {
  return <EuiProvider colorMode="LIGHT">{children}</EuiProvider>;
}
