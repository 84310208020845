"use client";
import { datadogRum } from "@datadog/browser-rum";
import { useIdentity } from "@equipmentshare/auth0-react";
import { useEffect } from "react";
import { getGPUTier } from "detect-gpu";

// https://docs.datadoghq.com/real_user_monitoring/guide/monitor-your-nextjs-app-with-rum/?tab=npm
if (process.env.NEXT_PUBLIC_ENVIRONMENT === "production") {
  datadogRum.init({
    beforeSend(event) {
      if (
        event.type === "error" &&
        event.error.message.includes("ResizeObserver")
      ) {
        return false;
      }
      return true;
    },
    applicationId: "6942b321-cc81-411c-a199-08889036fad9",
    clientToken: "pub4b8e89358ee50b65dc685bffb423b019",
    site: "datadoghq.com",
    service: "optimization",
    env: process.env.NEXT_PUBLIC_ENVIRONMENT,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: process.env.NEXT_PUBLIC_OPTIMIZATION_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
    enableExperimentalFeatures: ["feature_flags"],
    allowedTracingUrls: [
      `${process.env.NEXT_PUBLIC_GRAPHQL_API}/fleet-graphql`,
    ],
  });
}

export function DatadogInit() {
  // Render nothing - this component is only included so that the init code
  // above will run client-side
  return null;
}

export function UserContextInit() {
  // See: https://docs.datadoghq.com/real_user_monitoring/browser/modifying_data_and_context/?tab=npm#identify-user-session
  const { companyId, userId, fullName, email } = useIdentity();
  useEffect(() => {
    // return if dd is not enabled
    if (datadogRum.getInitConfiguration() === undefined) {
      return;
    }
    datadogRum.clearUser();
    datadogRum.setUser({
      id: userId.toString(),
      email,
      name: fullName,
      companyId,
    });
    datadogRum.startSessionReplayRecording();

    // collecting GPU stats
    const setGpuMetrics = async () => {
      const { tier, gpu, fps } = await getGPUTier();
      datadogRum.setUserProperty("gputier", tier);
      datadogRum.setUserProperty("gpu", gpu);
      datadogRum.setUserProperty("fps", fps);
    };
    setGpuMetrics().catch(() => {});
  }, [companyId, userId, fullName, email]);
  return null;
}
